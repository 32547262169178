body {
  margin: 0 auto;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 600px) {
  .facet_sidebar {
    display: none;
  }
}