html,
body {
  font-family: 'Forum', cursive;
}

.Ttd {
  min-height: 100vh;
  background-color: #1D7C84;
  text-align: center;
  color: #fff;
}

header.ttd-header {
  .ttd-logo {
    display: flex;
    align-items: center;
  }
}

.ttd-brand-name {
  font-weight: 600;
  font-size: 2em;
  color: #fff;
}

.ttd-gray-text {
  color: rgba(255, 255, 255, 0.3);
}

.ttd-red-text {
  color: rgba(255, 0, 0);
}

.ttd-body {
  display: flex;
  flex-direction: column;

  font-size: 3.36em;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
}

.ttd-button {
  border: 0.5px solid #fff;
  border-radius: 7px;
  padding: 5px;
  text-shadow: 0 1px rgba(0, 0, 0, 0.§);
  text-decoration: none;
  color: rgba(255, 255, 255, 0.7);
}

.ttd-footer {
  height: 10vh;
  background-color: #000;
  font-size: 1.2em;

  position: fixed;
  right: 0;
  bottom: 0;

  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: block;
  gap: 1em
}